<template>
  <Modal v-model="showModal" :mask-closable="false" width="1270px">
    <div slot="header">{{showLang(command.lang)}}</div>
    <Form :label-width="120">
      <FormItem :label-width="0" v-if="['openControl'].indexOf(command.code) != -1" style="margin-bottom: 50px">
        <div class="control-table" v-if="currentPower">
          <div class="control-item-area">
            <img class="control-item" :src="img.powerControl" />
          </div>
          <div v-for="n in 7" :key="n" class="control-box-line" :style="{left: (200 - 10 * n) + 'px', top: (80 - n * 10) + 'px', right: ((7 - n) * 140 + 95) + 'px'}"></div>
          <div class="control-list">
            <div :class="['control-box', form.channels.indexOf(item.content.oc) != -1 ? 'control-box-selected' : '']" v-for="(item, idx) in outputs" :key="`o${idx}`" @click="selectChannel(item)">
              <div v-if="form.channels.indexOf(item.content.oc) != -1" class="control-selected">{{showLang('com.op.Selected')}}</div>
              <div class="k-name tc">K{{item.content.oc}}</div>
              <img class="k-img" :src="item.lastData.ov == 0 ? img.light.road.single.viewer.contactClose : img.light.road.single.viewer.contactOpen" />
              <StatusButton class="control-box-button" type="button" :color="item.lastData.ov == 1 ? 'blue':'yellow'" :text="item.name" />
            </div>
            <div :class="['control-box', form.channels.indexOf(item.content.oc) != -1 ? 'control-box-selected' : '']" v-for="(item, idx) in locks" :key="`l${idx}`" @click="selectChannel(item)">
              <div v-if="form.channels.indexOf(item.content.oc) != -1" class="control-selected">{{showLang('com.op.Selected')}}</div>
              <div class="k-name tc">K{{item.content.oc}}</div>
              <img class="k-img" :src="item.lastData.ov == 0 ? img.light.road.single.viewer.contactClose : img.light.road.single.viewer.contactOpen" />
              <StatusButton class="control-box-button" type="button" :color="item.lastData.ov == 1 ? 'blue':'yellow'" :text="item.name" />
            </div>
            <div v-for="n in remainCount" class="control-box" :key="`r${n}`">
              <div class="k-name tc" style="border: 4px solid gray;">-</div>
              <img class="k-img" :src="img.light.road.single.viewer.contactClose" />
              <StatusButton class="control-box-button" type="button" color="gray" text="-" style="width:100px" />
            </div>
          </div>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.time.delay')" v-if="['handSingle', 'openControl', 'openSingle', 'closeSingle'].indexOf(command.code) != -1">
        <DatePicker type="datetime" v-model="form.expire" format="yyyy-MM-dd HH:mm:ss"></DatePicker>
      </FormItem>
      <FormItem :label="showLang('com.ins.ch')" v-if="['getYearPlan', 'setYearPlan'].indexOf(command.code) != -1">
        <Select v-model="form.channels" multiple>
          <template v-for="item in outputs">
            <Option v-if="item.content.oc > 0" :key="item.id" :value="item.content.oc">{{item.name}} - K{{item.content.oc}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.but.get.read.date')" v-if="['getYearPlan'].indexOf(command.code) != -1">
        <DatePicker type="date" v-model="form.date" format="yyyy-MM-dd"></DatePicker>
      </FormItem>
      <FormItem :label="showLang('com.tab.size.pack')" v-if="['update'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.packLen" type="number" number style="width: 120px; height: 32px">
          <span slot="append">{{showLang('com.unit.byte')}}</span>
          </Input>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.but.upgrade.file')" v-if="['update'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <div class="logo-upload">
            <input ref="fileInput" type="file" accept=".bin" :disabled="loading" />
          </div>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.group.number')" v-if="['setGroup', 'handGroup'].indexOf(command.code) != -1" class="form-item">
        <Input v-model="form.group" type="number" number style="width: 120px; height: 32px">
        <!-- <span slot="append">字节</span> -->
        </Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.op')+'1'" v-if="['handSingle', 'handGroup'].indexOf(command.code) != -1">
        <Slider v-model="form.bright1" show-input show-tip="always" style="width: 400px;"></Slider>
      </FormItem>
      <FormItem :label="showLang('com.lighting.op')+'2'" v-if="['handSingle', 'handGroup'].indexOf(command.code) != -1">
        <Slider v-model="form.bright2" show-input show-tip="always" style="width: 400px;"></Slider>
      </FormItem>
      <FormItem :label="showLang('com.lighting.oc')" v-if="['handSingle', 'handGroup'].indexOf(command.code) != -1">
        <Slider v-model="form.color" show-input show-tip="always" style="width: 400px;"></Slider>
      </FormItem>
      <FormItem :label="showLang('com.tab.ip.http')" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.host" type="text" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.ins.port')" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.port" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.ins.heartbeat')" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.heart" type="number" style="width: 120px;height:32px;"><span slot="append">s</span></Input>
      </FormItem>
      <FormItem :label="showLang('com.ins.timeTable')" v-if="['setDayPlan1'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.timeId">
          <template v-for="item in timeTables">
            <Option v-if="item.type == showLang('com.but.timing')" :key="item.id" :value="item.id">[{{item.type}}]{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.ins.timeTable')" v-if="['setDayPlan2'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.timeId">
          <template v-for="item in timeTables">
            <Option v-if="item.type == showLang('com.plan.light.time')" :key="item.id" :value="item.id">[{{item.type}}]{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.but.tableType')" v-if="['setPlanType'].indexOf(command.code) != -1" class="form-item">
        <RadioGroup v-model="form.planType">
          <Radio :label="0">{{showLang('com.but.timing')}}</Radio>
          <Radio :label="1">{{showLang('com.plan.light.time')}}</Radio>
          <Radio :label="2">{{showLang('com.but.mode.light')}}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem :label="showLang('com.ins.ch')+'1'+showLang('com.date.delayed')" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.keep1" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">S</span>
          </Input>
          <Slider v-model="form.keep1" :min="0" :max="100" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.ins.ch')+'2'+showLang('com.date.delayed')" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.keep2" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">S</span>
          </Input>
          <Slider v-model="form.keep2" :min="0" :max="100" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.is.sensing')" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.sensor">
          <Option :value="1">{{showLang('com.state.effective')}}</Option>
          <Option :value="2">{{showLang('com.state.invalid')}}</Option>
          <Option :value="0">{{showLang('com.state.no.modify')}}</Option>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.is.signal')" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.txen">
           <Option :value="128">{{showLang('com.state.effective')}}</Option>
          <Option :value="2">{{showLang('com.state.invalid')}}</Option>
          <Option :value="0">{{showLang('com.state.no.modify')}}</Option>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.sending.time')" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.period" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">S</span>
          </Input>
          <Slider v-model="form.period" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.linkage.number')" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.rfsn" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.rfsn" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="'433'+showLang('com.lighting.P')" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.txpower" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.txpower" :min="0" :max="8" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="command.code=='setUnionParam' ? '433'+showLang('com.lighting.channel2'):showLang('cmd.content.channel')" v-if="['setUnionParam', 'setLoraChannel'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.channel" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.channel" :min="0" :max="100" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.number.range')" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.range" type="number" number style="width: 120px;height:32px;">
          </Input>
          <Slider v-model="form.range" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.is.put.signal2')" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.rxen">
          <Option :value="1">{{showLang('com.op.yes')}}</Option>
          <Option :value="2">{{showLang('com.op.deny')}}</Option>
          <Option :value="0">{{showLang('com.state.no.modify')}}</Option>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.linkage.mode')" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.mode">
          <Option :value="1">{{showLang('com.lighting.Linkage.control')}}</Option>
          <Option :value="2">{{showLang('com.lighting.lamp.contact')}}</Option>
          <Option :value="0">{{showLang('com.state.no.modify')}}</Option>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.repeat.num')" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.repeat" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.repeat" :min="0" :max="10" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.ins.alarm.time')" v-if="['setAlarmRange'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.interval" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">min</span>
          </Input>
          <Slider v-model="form.interval" :min="10" :max="1440" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.V.max')" v-if="['setAlarmRange'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.vhigh" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">V</span>
          </Input>
          <Slider v-model="form.vhigh" :min="0" :max="500" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.V.min')" v-if="['setAlarmRange'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.vlow" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">V</span>
          </Input>
          <Slider v-model="form.vlow" :min="0" :max="220" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.I.max')" v-if="['setAlarmRange'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.chigh" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">A</span>
          </Input>
          <Slider v-model="form.chigh" :min="0" :max="20" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.I.min')" v-if="['setAlarmRange'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.clow" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">A</span>
          </Input>
          <Slider v-model="form.clow" :min="0" :max="20" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.T.max')" v-if="['setAlarmRange'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.thigh" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">℃</span>
          </Input>
          <Slider v-model="form.thigh" :min="0" :max="100" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.T.min')" v-if="['setAlarmRange'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.tlow" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">℃</span>
          </Input>
          <Slider v-model="form.tlow" :min="0" :max="100" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.state.is.v.police')" v-if="['setAlarmStatus'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.voltageAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.state.is.i.police')" v-if="['setAlarmStatus'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.currentAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.state.is.t.police')" v-if="['setAlarmStatus'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.tempAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem :label="showLang('alarm.type.gyro')" v-if="['setGyroLevel'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.gyrox" type="number" number style="width: 150px;height:32px;">
            <span slot="prepend">{{showLang('com.data.x')}}</span>
          <!-- <span slot="append">℃</span> -->
          </Input>
          <Input v-model="form.gyroy" type="number" number style="width: 150px;height:32px;margin: 0 10px">
            <span slot="prepend">{{showLang('com.data.y')}}</span>
          <!-- <span slot="append">℃</span> -->
          </Input>
          <Input v-model="form.gyroz" type="number" number style="width: 150px;height:32px;">
            <span slot="prepend">{{showLang('com.data.z')}}</span>
          <!-- <span slot="append">℃</span> -->
          </Input>
          <!-- <Slider v-model="form.gyroLevel" :min="0" :max="10000" style="flex:auto;margin: 0 15px"></Slider> -->
        </div>
      </FormItem>
      <FormItem :label="showLang('com.pswd.confirm')" v-if="command.needPswd">
        <Input v-model="form.pswd" type="password" ref="pswd"  style="width: 220px;height:32px;">
            <Button slot="append" icon="ios-eye" @click="eyeOutline()"></Button>
          </Input><span style="color:red;margin-left:20px">*** {{showLang('com.pswd.user.confirm')}}</span>
      </FormItem>
      <FormItem :label="showLang('com.but.switch.control')" v-if="['openControl'].indexOf(command.code) != -1">
        <!-- <i-switch size="large" v-model="form.open">
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch> -->
        <Button style="margin-right: 8px" @click="closeLightOneKey">{{showLang('com.but.key.close')}}</Button>
        <Button style="margin-right: 8px" type="error" @click="openLightOneKey">{{showLang('com.but.key.open')}}</Button>
      </FormItem>
      <FormItem :label="showLang('com.ins.light.val')" v-if="['setLuxOpenValue'].indexOf(command.code) != -1" class="form-item">
        <Input v-model="form.lux" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="append">字节</span> -->
        </Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <Button v-if="['openControl'].indexOf(command.code) == -1" style="margin-right: 8px" type="primary" @click="ok">{{showLang('com.lighting.sending')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import StatusButton from './StatusButton'
export default {
  name: 'ModalControlOutput',
  components: {
    StatusButton,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    command: {
      type: Object,
      default() { return {}; }
    }
  },
  data() {
    return {
      showModal: this.value,
      date: "",
      modalSetForm: false,
      userPassword: "",
      loading: false,
      isSwitch: true,
      outputs: [],
      locks: [],
      timeTables: [],
      form: {
        timeId: 0,
        planType: 0,
        pswd: '',
        channels: [],
        params: [],
        open: false,
        expire: '',
        date: '',
        packLen: 512,
        host: '',
        port: 1000,
        heart: 30,
        bright1: 100,
        bright2: 100,
        group: 1, //分组号
        color: 100, //色温
        rfsn: 1, //联动模块编号
        channel: 1, //433应用频道
        txpower: 3, //433发送功率
        txen: 128, //是否发送433信号,对于检测方可以发送，其他的只能RX
        repeat: 1, //重发次数 0~10有效，默认2
        period: 3, //重发周期
        range: 5, //响应范围，这个对发送方无意义，对接收方有用
        rxen: 1, //是否接收433联动信号  1=允许接收
        mode: 2, //联动方式， 1=按Range ,2:全局，Range无效
        keep1: 10, //灯1延时时间（秒）
        keep2: 1, //灯2延时时间（秒）
        sensor: 1, //是否启用感应启用，RX方只管自己
        voltageAlarm: false,//电压是否报警
        currentAlarm: false,//电流是否报警
        tempAlarm: false,//温度是否报警
        gyrox: 3000, //倾斜报警阈值
        gyroy: 3000, //倾斜报警阈值
        gyroz: 3000, //倾斜报警阈值
        interval: 60,//报警间隔时间，单位分钟
        vhigh: 220,//电压上限值
        vlow: 5,//电压下限值
        chigh: 10,//电流上限值
        clow: 0.3,//电流下限值
        thigh: 70,//温度上限值
        tlow: 10,//温度下限值
        lux: 100,//光感开灯值
      },
    }
  },
  computed: {
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapGetters("auth", ["showLang"]),
    currentPower: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode
      }
      return null;
    },
    remainCount: function () {
      // return 0;
      if (!this.currentPower) return 0;
      let cnt = 7;
      if (this.outputs) {
        cnt -= this.outputs.length;
      }
      if (this.locks) {
        cnt -= this.locks.length;
      }
      if (cnt < 0) cnt = 0;
      return cnt;
    },
  },
  mounted: function () {
    // let d = new Date();
    // d.setHours(d.getHours() + 1);
    // this.date = d.format('yyyy-MM-dd  HH:mm:ss');
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.$set(this.form, 'channels', []);
        let now = new Date();
        this.form.date = now.format('yyyy-MM-dd');
        now.setHours(now.getHours() + 1);
        this.form.expire = now.format('yyyy-MM-dd HH:mm:ss');
        this.pswd = '';
        if(this.command.code == 'openControl'){
          this.initOutputs();
          this.initLocks();
        }else if(this.command.code == 'setYearPlan' || this.command.code == 'getYearPlan'){
          this.initOutputs();
        }else if(['setDayPlan1','setDayPlan2'].indexOf(this.command.code) != -1){
          this.getUseablePlan();
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  methods: {
     eyeOutline() {
      this.$refs.pswd.type = 'text'
      setTimeout(() => {
        if (this.$refs.pswd) {
          this.$refs.pswd.type = 'password'
        }
      }, 3000)
      console.log('eyeOutline')
    },
    getUseablePlan: function(){
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    initOutputs: function(){
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryOutput`, {groupId, stationId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'outputs', res.data);
        }
      });
    },
    initLocks: function(){
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryLock`, {groupId, stationId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'locks', res.data);
        }
      });
    },
    selectChannel: function (item) {
      let ch = item.content.oc;
      let idx = this.form.channels.indexOf(ch);
      if (idx == -1) {
        this.form.channels.push(ch);
        this.form.params.push(item.id);
      } else {
        this.form.channels.splice(idx, 1);
        this.form.params.splice(idx, 1);
      }
    },
    closeLightOneKey: function(){
      if (this.form.channels.length == 0) {
        this.$Message.warning(this.showLang('save.select')+this.showLang('com.ins.ch'));
        return;
      }
      if (this.command.code == 'openControl') {
        let d = new Date(this.form.expire);
        if (d < new Date()) {
          this.$Message.warning(this.showLang('com.time.future.choose'));
          return;
        }
      }
      if (this.form.pswd == '') {
        this.$Message.warning(this.showLang('save.batch.pswd'));
        return;
      }
      this.$store.dispatch('auth/checkPswd', { pswd: this.form.pswd }).then(res => {
        if (res.code != 0) {
          // this.$Message.error('密码错误');
          return;
        } else {
          this.form.open = false;
          this.createCmd();
        }
      });
    },
    openLightOneKey: function(){
      if (this.form.channels.length == 0) {
         this.$Message.warning(this.showLang('com.time.future.choose'));
        return;
      }
      if (this.command.code == 'openControl') {
        let d = new Date(this.form.expire);
        if (d < new Date()) {
          this.$Message.warning(this.showLang('com.time.future.choose'));
          return;
        }
      }
      if (this.form.pswd == '') {
       this.$Message.warning(this.showLang('save.batch.pswd'));
        return;
      }
      this.$store.dispatch('auth/checkPswd', { pswd: this.form.pswd }).then(res => {
        if (res.code != 0) {
          // this.$Message.error('密码错误');
          return;
        } else {
          this.form.open = true;
          this.createCmd();
        }
      });
    },
    ok: function () {
      if (this.command.code == "update") {
        if (this.$refs.fileInput.files.length == 0) {
          this.$Message.warning(this.showLang('save.upgrade.file'));
          return;
        }
        if (this.form.pswd == '') {
          this.$Message.warning(this.showLang('save.batch.pswd'));
          return;
        }
        this.$store.dispatch('auth/checkPswd', { pswd: this.form.pswd }).then(res => {
          if (res.code != 0) {
            // this.$Message.error('密码错误');
            return;
          } else {
            var reader = new FileReader(); //实例化文件读取对象
            reader.readAsDataURL(this.$refs.fileInput.files[0]); //将文件读取为 DataURL,也就是base64编码
            let that = this;
            reader.onload = function (ev) {
              //文件读取成功完成时触发
              var dataURL = ev.target.result; //获得文件读取成功后的DataURL,也就是base64编码
              // document.querySelector("img").src = dataURL; //将DataURL码赋值给img标签
              that.form.content = dataURL.replace("data:application/octet-stream;base64,", "");
              // that.$emit('saved', {code: that.code, name: that.name, packLen: that.form.packLen, args: dataURL})
              // that.argsSaved({
              //   code: that.code,
              //   name: that.name,
              //   args: { len: that.form.packLen, content: dataURL },
              // });
              that.createCmd();
              // that.loading = false;
              // that.showModal = false;
              that.$refs.fileInput.value = "";
            };
          }
        });
        return;
      }
      if (this.command.code == 'openSingle' || this.command.code == 'closeSingle') {
        let d = new Date(this.form.expire);
        if (d < new Date()) {
          this.$Message.warning(this.showLang('com.time.future.choose'));
          return;
        }
      }
      if (this.command.code == 'setGroup') {
        if (this.form.groupNo < 0 || this.form.groupNo > 255) {
          this.$Message.warning(this.showLang('com.save.group.err.code',0,255));
          return;
        }
      }
      if(this.command.code == 'setLuxOpenValue'){
        if(this.form.lux < 0 || this.form.lux > 65535){
          this.$Message.warning(this.showLang('com.ins.light.val.between',0,65535));
          return;
        }
      }
      if (this.command.code == 'setDayPlan1' || this.command.code == 'setDayPlan2') {
        if (!this.form.timeId) this.form.timeId = 0;
        if (this.form.timeId <= 0) {
          this.$Message.warning(this.showLang('save.light.plan.need'));
          return;
        }
      }
      if (this.command.code == 'handControl') {
        let d = new Date(this.form.expire);
        if (d < new Date()) {
          this.$Message.warning(this.showLang('com.time.future.choose'));
          return;
        }
      }
      if (this.command.code == 'setNetwork') {
        if (this.form.port < 0 || this.form.port > 65535) {
          this.$Message.warning(this.showLang('com.save.group.port.code',1,65535));
          return;
        }
      }
      if (this.command.code == 'setCurrentRate') {
        if (this.form.currentRate < 1 || this.form.currentRate > 65535) {
          this.$Message.warning(this.showLang('com.i.parameters.between',1,65535));
          return;
        }
      }
      if (this.command.needPswd) {
        if (this.form.pswd == '') {
          this.$Message.warning(this.showLang('save.batch.pswd'));
          return;
        }
        this.$store.dispatch('auth/checkPswd', { pswd: this.form.pswd }).then(res => {
          if (res.code != 0) {
            // this.$Message.error('密码错误');
            return;
          } else {
            this.createCmd();
          }
        });
      } else {
        this.createCmd();
      }
    },
    createCmd: function () {
      let args;
      if (this.command.code == 'openSingle') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = { bright1: 100, bright2: 0, color1: 100, color2: 0, expire: expire };
      } else if (this.command.code == 'closeSingle') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = { bright1: 0, bright2: 0, color1: 0, color2: 0, expire: expire };
      } else if (this.command.code == 'handSingle') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = { bright1: this.form.bright1, bright2: this.form.bright2, color1: this.form.color, color2: 100 - this.form.color, expire: expire };
      } else if (this.command.code == 'handGroup') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = { no: this.form.group, bright1: this.form.bright1, bright2: this.form.bright2, color1: this.form.color, color2: 100 - this.form.color, expire: expire };
      } else if (this.command.code == 'setGroup') {
        args = { no: this.form.group };
      } else if (this.command.code == 'setPlanType') {
        args = { type: this.form.planType };
      } else if (this.command.code == 'setDayPlan1' || this.command.code == 'setDayPlan2') {
        args = { timeId: this.form.timeId };
      } else if (this.command.code == 'handControl') {
        args = { open: this.form.open, expire: new Date(this.form.expire).format('yyyy-MM-dd HH:mm:ss'), point: false };
      } else if (this.command.code == 'openControl') {
        args = { channels: this.form.channels, params: this.form.params, open: this.form.open, expire: new Date(this.form.expire).format('yyyy-MM-dd HH:mm:ss'), point: false, locks: this.locks.map(p => p.content.oc) };
      } else if (this.command.code == 'getYearPlan') {
        let date = new Date(this.form.date);
        args = { channels: this.form.channels, month: date.getMonth() + 1, day: date.getDate() };
      } else if (this.command.code == 'setYearPlan') {
        let timeIds = [];
        for (let ch of this.form.channels) {
          let opts = this.outputs.filter(p => p.content.oc == ch);
          if (opts.length > 0) {
            timeIds.push(opts[0].content.timeId)
          } else {
            timeIds.push(0);
          }
        }
        args = { channels: this.form.channels, timeIds: timeIds };
      } else if (this.command.code == 'setCurrentRate') {
        args = { k: this.form.currentRate };
      } else if (this.command.code == 'update') {
        args = { version: 3, flag: 0, len: this.form.packLen, content: this.form.content };
      } else if (this.command.code == 'setNetwork') {
        args = { host: this.form.host, port: this.form.port, heart: this.form.heart };
      } else if (this.command.code == 'setUnionParam') {
        args = {
          rfsn: this.form.rfsn,
          channel: this.form.channel,
          txpower: this.form.txpower,
          txen: this.form.txen,
          repeat: this.form.repeat,
          period: this.form.period,
          range: this.form.range,
          rxen: this.form.rxen,
          mode: this.form.mode,
          keep1: this.form.keep1,
          keep2: this.form.keep2,
          sensor: this.form.sensor,
        };
      } else if (this.command.code == 'setAlarmRange') {
        args = {
          vHigh: this.form.vhigh,
          vLow: this.form.vlow,
          cHigh: this.form.chigh,
          cLow: this.form.clow,
          tHigh: this.form.thigh,
          tLow: this.form.tlow,
          interval: this.form.interval,
        };
      } else if (this.command.code == 'setAlarmStatus') {
        args = { voltage: this.form.voltageAlarm, current: this.form.currentAlarm, temp: this.form.tempAlarm };
      } else if (this.command.code == 'setGyroLevel') {
        args = { lx: this.form.gyrox, ly: this.form.gyroy, lz: this.form.gyroz };
      }else if(this.command.code == 'setLuxOpenValue'){
        args = {lux: this.form.lux};
      } else {
        args = {};
      }
      this.$emit('saved', { cmd: this.command, paramsCode: this.command.paramsCode, args });
      // this.showModal = false;
      this.form.pswd = '';
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },
    okSetForm() {
      if (this.userPassword.length == 0) {
        this.$Message.warning(this.showLang('save.batch.pswd'));
        return;
      }
      this.loading = true;
      let this_ = this;
      this.$store.dispatch('auth/checkPswd', { pswd: this.userPassword }).then(res => {
        this.loading = false;
        if (res.code != 0) {
          // this.$Message.error('密码错误');
        } else {
          this_.modalSetForm = false;
          this.args.open = this.isSwitch;
          this_.$emit('doCmd', this.args);
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.control-selected {
  position: absolute;
  top: 2px;
  right: 2px;
  color: red;
  background-color: white;
  padding: 0 5px;
  line-height: 20px;
  /* border: solid 1px red; */
}
.k-img {
  width: 80px;
  height: 40px;
  margin: 35px;
}
.k-name {
  width: 40px;
  height: 40px;
  line-height: 32px;
  background: #ffffff;
  border: 4px solid #83a8d9;
  border-radius: 20px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #324252;
}
.control-table {
  width: 100%;
  height: 280px;
  display: flex;
  z-index: 20;
  background-color: white;
  overflow: hidden;
  /* border: solid 1px red; */
}
.control-item-area {
  width: 168px;
  height: 231px;
  flex: none;
  z-index: 2;
  margin-left: 50px;
  margin-top: 110px;
  /* border: solid 1px red; */
  background-color: #ffffff;
}
.control-item {
  width: 168px;
  height: 131px;
  margin-left: 0px;
  /* border: solid 1px red; */
}
.control-list {
  position: absolute;
  top: 90px;
  left: 230px;
  display: flex;
  align-items: center;
  /* border: solid 1px red; */
  padding-top: 20px;
}
.control-box {
  width: 130px;
  height: 200px;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px white;
  position: relative;
  cursor: pointer;
}
.control-box-selected {
  border: solid 1px rgb(5, 187, 211);
  border-radius: 6px;
}
.control-box-line {
  position: absolute;
  bottom: 10px;
  border: solid 1px #779ac0;
  border-radius: 10px;
  border-bottom: 0;
}
.control-box-button {
  margin: 0 auto 20px auto;
  text-align: center;
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  color: #ffffff;
  text-shadow: 0px 1px 4px rgba(155, 132, 0, 0.66);
  background-size: 100% 100%;
}
</style>